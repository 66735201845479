import Head from 'next/head'
import * as React from 'react'

const Layout: React.FC<any> = (props) => {
  return (
    <div>
      <Head>
        <title>{props.title}</title>
      </Head>

      <div>{props.children}</div>

      <footer
        className="fixed p-2.5 bottom-0 w-full flex items-center justify-center h-10 border-t-[1px] border-primary-300 margin-0 bg-bg group"
        data-testid="footer"
      >
        <p
          className="inline-block opacity-50 text-xs m-0 pr-1 mr-1 md:text-sm md:mr-2.5 md:pr-2.5 transition-all border-r-[1px] border-primary-600 group-hover:opacity-100"
          data-testid="footerCopyrightText"
        >
          © {new Date().getFullYear()} Strive Messaging Inc.
        </p>
        <p className="inline-block  opacity-50 text-xs m-0 pr-1 mr-1 md:text-sm md:mr-2.5 md:pr-2.5 transition-all border-r-[1px] border-primary-600 group-hover:opacity-100">
          <a
            href="https://www.strivemessaging.org/terms/"
            target="__blank"
            data-testid="footerTosLink"
            className="text-xs md:text-sm"
          >
            Terms
          </a>
        </p>
        <p className="inline-block opacity-50 text-xs m-0 pr-1 mr-1 md:text-sm md:mr-2.5 md:pr-2.5 transition-all border-r-[1px] border-primary-600 group-hover:opacity-100">
          <a
            href="https://www.strivemessaging.org/privacy/"
            target="__blank"
            data-testid="footerPrivacyPolicyLink"
            className="text-xs md:text-sm"
          >
            Privacy
          </a>
        </p>
        <p className="inline-block opacity-50 text-xs m-0 pr-1 mr-1 md:text-sm md:mr-2.5 md:pr-2.5 transition-all border-r-[1px] border-primary-600 group-hover:opacity-100">
          Powered by{' '}
          <a
            href="https://www.strivemessaging.org/"
            target="__blank"
            data-testid="footerPoweredByLink"
            className="text-xs md:text-sm"
          >
            Strive Messaging
          </a>
        </p>
        <p className="inline-block opacity-50 text-xs m-0 pr-1 mr-1 md:text-sm md:mr-2.5 md:pr-2.5 transition-all group-hover:opacity-100">
          Need help?{' '}
          <a
            href="mailto:help@strivemessaging.org"
            data-testid="footerContactUsLink"
            className="text-xs md:text-sm"
          >
            Contact us
          </a>
        </p>
      </footer>
    </div>
  )
}
export default Layout
